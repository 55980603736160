body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* -------------------------------------------- */
/* App.js */

.home-container{
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* deal with error */
.empty-image {
  background-image: url(/static/media/meh.a507119e.svg);
  width: 100%;
  height: 100px;
  position: relative;
  flex: 1 1;
  background-repeat: no-repeat;
  background-position: center center;
}

/* ---------- */
/* SCROLL BAR */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: silver whitesmoke;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
  border-radius: 6px;
  scroll-behavior: smooth !important;
}
*::-webkit-scrollbar-track {
  background: whitesmoke;
}
*::-webkit-scrollbar-thumb {
  background-color: silver;

  /* border: 3px solid orange; */
}


/* -------------------------------------------- */
/* Result.js */
.container-result {
  position: relative;
  flex: 1 1;
}

.content-result{
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
}

.container-card-result{
  overflow-y:scroll;
  background-color:#f5f5f5 !important;
}

@media (max-width:992px) {
  .col-card{
    width: 100%;
  }
  .col-map{
    width: 0%;
  }
}


@media (min-width: 992px) {
  .col-card{
    width: 45%;
  }
  .col-map{
    width: 55%;
  }
}
@media (min-width: 1200px) {
  .col-card{
    width: 40%;
  }
  .col-map{
    width: 60%;
  }
}
@media (min-width: 1600px) {
  .col-card{
    width: 33%;
  }
  .col-map{
    width: 77%;
  }
}



/* -------------------------------------------- */
/* GolfMap.js */

/* ---------------------------------- */
/* Button in [landing.js, contact.js] */
.butn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.butn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.button-submit {
  background-color:#DBAE37;
  font-weight: 550;
  color:white;
}

.button-submit:hover {
  background-color:#bd911e;
  color:whitesmoke;
}
